<template>
  <v-card :color="statusColor.card">
    <v-container>
      <v-row dense align="center">
        <v-col cols="6" align-center class="text-center pa-6"
          ><v-avatar
            v-if="isValid"
            class="ma-3 outlined"
            size="125"
            @click="openQRCode"
          >
            <v-img :src="stage.resource.pass"></v-img>
            <!-- <v-img :src="passImg(stage.resource.pass)"></v-img>  -->
          </v-avatar></v-col
        >
        <v-col cols="6">
          <v-card-subtitle>
            <v-btn
              v-if="isValid"
              @click="openQRCode"
              :dark="dark"
              small
              outlined
              rounded
            >
              Ampliar pase
            </v-btn>
          </v-card-subtitle>
          <v-card-title class="my-auto" :class="msgStyle" @click="openQRCode">{{
            msgText
          }}</v-card-title>
        </v-col>
      </v-row>
    </v-container>
    <code-dialog
      ref="dialog"
      :item="editedItem"
      :stage="stage"
      :passImg="passImg"
    ></code-dialog>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { managedImg } from '@/micro/img'
import CodeDialog from '@/components/requests/views/CodeDialog'
export default {
  components: {
    CodeDialog
  },
  props: {
    editedItem: {
      type: Object,
      required: true
    },
    stage: {
      type: Object,
      required: true
    },
    statusOf: {
      type: Function,
      required: true
    },
    action: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters('workflows', ['statusColorByStage']),
    statusColor() {
      return this.statusColorByStage(this.stage, this.status, this.editedItem)
    },

    textColor() {
      const parts = this.statusColor.icon.split(' ')
      const color = `${parts[0]}--text text--${parts[1]}`
      return color
    },

    status() {
      return this.statusOf(this.stage, this.editedItem)
    },

    passImg() {
      return qrImg => {
        return qrImg ? this.managedImg(qrImg) : ''
      }
    },
    isValid() {
      return this.status === 'approved' ? true : false
    },
    msgText() {
      return this.isValid ? this.stage.resource.code : `El código ha caducado`
    },
    msgStyle() {
      return this.isValid
        ? `headline ${this.textColor} font-weight-bold text--spacing`
        : `${this.textColor} font-weight-bold text-overline`
    },
    dark() {
      return this.stage.id === 'pass1' ? true : false
    }
  },
  methods: {
    managedImg,
    openQRCode() {
      if (this.isValid) {
        this.$refs.dialog.open()
      }
    }
  },
  mounted() {
    if (this.isValid && this.action === 'to_checkin') {
      this.$refs.dialog.open()
    }
  }
}
</script>

<style scoped>
.text--spacing {
  letter-spacing: 5px !important;
}
</style>
