<template>
  <div>
    <!-- Auth -->
    <div
      v-if="doesRequireAuth(item)"
      class="d-flex justify-center mr-4 mb-4 mb-sm-0"
    >
      <span>Autorizar: </span>
      <!-- Rejection -->
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            class="mx-2"
            fab
            :loading="loading.reject"
            :disabled="disabled.reject"
            @click="open(item, 'reject')"
          >
            No <v-icon color="red accent-3">mdi-cancel</v-icon>
          </v-btn>
        </template>
        <span>Rechazar</span>
      </v-tooltip>

      <!-- Approval -->
      <div class="d-flex justify-center">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              class="mx-2"
              fab
              :loading="loading.approve"
              :disabled="disabled.approve"
              @click="open(item, 'approve')"
            >
              Si
              <v-icon color="light-green darken-1"
                >mdi-check-circle-outline</v-icon
              >
            </v-btn>
          </template>
          <span>Aceptar</span>
        </v-tooltip>
      </div>
    </div>

    <!-- Default -->
    <div v-else class="d-flex justify-center mr-4">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            :rounded="true"
            class="mx-2"
            @click="open(item, status)"
          >
            {{ label }}
            <v-icon :color="statusColor" class="ml-2">
              {{ icon }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ tooltip }}</span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { isAuthRoleActivity } from '@/components/requests/activityControl'
import { nextActionTexts } from '@/components/requests/workflow/actionModel'
import {
  currentStageOf,
  isAuth2Stage,
  isNextInStage,
  isNextStage
} from '@/components/requests/workflow/stagesControl'
import { isWhithinTimeToAuth } from '../auths/authsTimeControl'
import { isWhithinTimeToAuth3 } from '../auths/auths3TimeControl'
import {
  hasExpired,
  isApproved,
  isError,
  isRejected,
  periodIn,
  resourceInRequest
} from '../reqsControl'
import { isRequestInLifeSpan } from '../reqsTimeControl'

export default {
  data: () => ({
    loading: {
      approve: false,
      reject: false
    },
    disabled: {
      approve: false,
      reject: false
    }
  }),
  props: {
    type: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    dialog: {
      type: Object,
      required: false
    },
    currentStatus: {
      type: Function,
      required: true
    }
  },

  computed: {
    ...mapGetters('workflows', [
      'willNextStageAuth1',
      'willNextStageAuth2',
      'willNextStageAuth3',
      'willNextStageEnd'
    ]),
    ...mapGetters('user', ['isSupervisor', 'isHeadOfSecurity', 'isConsultant']),
    ...mapGetters('requests', ['isNewRequestSending', 'isNewRequestSent']),

    isNextAuth2() {
      const currentStage = currentStageOf(this.item.workflow.stages)
      const isNextAuth2 = isNextInStage(isAuth2Stage)
      return isNextAuth2(currentStage)
    },

    // ...mapGetters('workflows', ['statusColorByStage']),

    statusColor() {
      const currentStage = currentStageOf(this.item.workflow.stages)
      const isNextEnd = this.willNextStageEnd(this.item)
      const expired = hasExpired(currentStage)
      let color = 'grey accent-1'
      const valid = isRequestInLifeSpan(this.item) && this.status !== 'error'
      if (isNextEnd) {
        color = 'grey lighten-4'
      } else if (expired || !valid) {
        color = 'brown darken-1'
      } else if (isRejected(currentStage) || isError(currentStage)) {
        color = 'red accent-3'
      } else if (isApproved(currentStage) && !isNextEnd) {
        color = 'light-green darken-1'
      }
      return color
    },

    doesRequireAuth() {
      return item => {
        const stage = currentStageOf(item.workflow.stages)
        const canAuth =
          isAuthRoleActivity(this.type) && stage.status === 'approved'
        const resource = resourceInRequest(item)
        const onTimeAuths = isWhithinTimeToAuth(periodIn(resource))
        const isAuth1 = this.isSupervisor && this.willNextStageAuth1(item)
        const isAuth2 = this.isHeadOfSecurity && this.willNextStageAuth2(item)
        const isAuth3 = this.isConsultant && this.willNextStageAuth3(item)
        return (
          canAuth &&
          ((onTimeAuths && (isAuth1 || isAuth2)) ||
            (isAuth3 && isWhithinTimeToAuth3(periodIn(resource))))
        )
      }
    },

    label() {
      const who = this.willNextStageAuth2(this.item)
        ? 'Seguridad'
        : this.willNextStageAuth3(this.item)
        ? 'Salud'
        : ''
      const label = this.text(this.type, this.status).label
      return who.length > 0 ? `${label} ${who}` : label
    },

    icon() {
      return this.text(this.type, this.status).icon
    },

    tooltip() {
      return this.text(this.type, this.status).tooltip
    },

    text() {
      if (this.isNewRequestSending || this.status === 'loading') {
        return () => {
          return {
            label: '...',
            tootltip: '...',
            icon: 'mdi-send'
          }
        }
      } else {
        return (type, status) => {
          return nextActionTexts(type, status)
        }
      }
    },

    status() {
      const current = currentStageOf(this.item.workflow.stages)
      return this.currentStatus(current, this.item, isNextStage(this.$store))
    }
  },

  methods: {
    open(item, status) {
      this.dialog.open(item, status, this.resetLoading)
      if (status === 'approve') {
        this.setLoadingApprove()
      } else {
        this.setLoadingReject()
      }
    },

    setLoadingApprove() {
      this.loading.approve = true
      this.disabled.reject = true
    },

    setLoadingReject() {
      this.loading.reject = true
      this.disabled.approve = true
    },

    resetLoading() {
      this.loading = { approve: false, reject: false }
      this.disabled = { approve: false, reject: false }
    }
  }
}
</script>
