<template>
  <v-card elevation="2" rounded="lg">
    <v-img :src="avatar" :max-height="maxHeight">
      <v-container class="fill-height" v-if="isApproved">
        <v-row align="start">
          <v-row justify="end">
            <div class="mr-8 mt-n8">
              <v-icon>mdi-checkbox-marked-circle</v-icon>
            </div>
          </v-row>
        </v-row>
      </v-container>
    </v-img>
    <v-card-title
      class="d-inline-block text-overline text-center mb-6 mt-3"
      v-if="name"
      >{{ name }}</v-card-title
    >

    <v-card-text>
      <div>
        <v-icon x-small>mdi-clock</v-icon> Duración
        <strong> {{ hrs }} horas</strong> <v-icon>mdi-check</v-icon>
      </div>
      <div>
        <v-icon x-small>mdi-clock</v-icon> No. folio
        <strong> COVID00XXXXXXX</strong> <v-icon>mdi-check</v-icon>
      </div>
      <div>
        <v-icon x-small>mdi-clock</v-icon> Emisor
        <strong> Gobierno de México / IMSS</strong> <v-icon>mdi-check</v-icon>
      </div>
      <div>
        <v-icon x-small>mdi-clock</v-icon> Fecha
        <strong> Ciudad de México a X de X de 202X</strong>
        <v-icon>mdi-check</v-icon>
      </div>
    </v-card-text>
    <v-card-actions class="px-8">
      <v-container fluid class="ma-0 pa-0">
        <v-row justify="space-around">
          <span class="group">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  :href="link"
                  color="identity"
                  target="_blank"
                  rel="noopener noreferrer"
                  large
                  rounded
                  outlined
                  >Inspeccionar documento
                  <v-icon color="success">mdi-eye-check-outline</v-icon></v-btn
                >
              </template>
              <span>Abrir en nueva ventana</span>
            </v-tooltip>
          </span>
        </v-row>
      </v-container>
    </v-card-actions>
  </v-card>
</template>

<script>
import semaphoreColor from '@/micro/supervision/semaphoreColor'
import 'firebase/firestore'
import 'firebase/storage'
import { currentStageOf } from '@/components/requests/workflow/stagesControl'
import { isPhone } from '@/micro/display'

export default {
  props: {
    editedItem: {
      type: Object,
      required: true
    },
    requirementId: {
      type: String,
      required: true
    },
    resourceId: {
      type: String,
      required: true
    },
    avatar: {
      type: String,
      default: require('@/assets/henkel-logo-standalone-svg.svg')
    },
    link: {
      type: String,
      required: true
    },
    hrs: {
      type: Number,
      required: true
    },
    name: {
      type: String,
      required: false
    },
    universe: {
      type: Number,
      required: false
    },
    stats: {
      type: Object,
      required: false
    }
  },

  computed: {
    completed() {
      return (this.stats.verified / this.universe) * 100
    },
    semaphore() {
      return semaphoreColor(this.completed)
    },
    isApproved() {
      const stage = currentStageOf(this.editedItem.workflow.stages)
      return stage.status === 'approved'
    },
    isPhone() {
      return isPhone(this.$vuetify)
    },
    maxHeight() {
      return this.isPhone ? '100' : '250'
    }
  }
}
</script>

<style lang="scss">
.v-card__title {
  height: 60px;
}
.v-application .text-overline {
  line-height: 1.1rem;
}
</style>
