var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":"","dense":""}},[_c('v-snackbar',{attrs:{"centered":"","timeout":"4000"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"grey","text":""},on:{"click":function($event){return _vm.hideSendingSnack()}}},'v-btn',attrs,false),[_vm._v(" Cerrar ")])]}}]),model:{value:(_vm.snackbarSendingState.ack),callback:function ($$v) {_vm.$set(_vm.snackbarSendingState, "ack", $$v)},expression:"snackbarSendingState.ack"}},[_vm._v(" "+_vm._s(_vm.snackbarSendingState.msg)+" "),_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v("mdi-send")])],1),[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"id","sort-by":_vm.sortBy,"sort-desc":false,"search":_vm.search,"locale":"es-MX","group-by":_vm.groupBy,"show-group-by":"","hide-default-header":!_vm.isPhone,"loading":_vm.loading,"loading-text":"Cargando... por favor espere."},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"mb-5",attrs:{"color":"blue-grey lighten-5","elevation":"1"}},[_c('v-text-field',{staticClass:"mx-2",attrs:{"label":"Buscar","append-icon":"mdi-magnify","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-switch',{staticClass:"mt-4 ml-6 mr-3 red--text",attrs:{"dense":"","flat":"","label":"Pendientes"},model:{value:(_vm.onlyPendingSelection),callback:function ($$v) {_vm.onlyPendingSelection=$$v},expression:"onlyPendingSelection"}})],1),_c('stages-dialog',{ref:"dialog",attrs:{"type":_vm.type,"items":_vm.items}})]},proxy:true},(!_vm.isPhone)?{key:"header",fn:function(props){return [_c('thead',[_c('tr',[_vm._l((props.props.headers),function(header){return [_c('th',{key:header.value},[_c('span',{class:_vm.sortedTableFieldStyle(header.value, props.props.options),on:{"click":function($event){$event.stopPropagation();return props.on.sort(header.value)}}},[_vm._v(_vm._s(header.text))]),_c('v-btn',{staticStyle:{"width":"20px"},attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return props.on.sort(header.value)}}},[(
                      _vm.isTableSortedByValue(
                        header.value,
                        props.props.options
                      ) < 0
                    )?_c('v-icon',{attrs:{"small":"","color":"black"}},[_vm._v("mdi-arrow-down")]):(
                      _vm.isTableSortedByValue(
                        header.value,
                        props.props.options
                      ) > 0
                    )?_c('v-icon',{attrs:{"small":"","color":"black"}},[_vm._v("mdi-arrow-up")]):_vm._e()],1),_c('v-btn',{staticStyle:{"width":"20px"},attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return props.on.group(header.value)}}},[_c('v-icon',{staticClass:"grey--text text--darken-2",attrs:{"small":""}},[_vm._v("mdi-format-list-group")])],1)],1)]})],2)])]}}:null,{key:"group.header",fn:function(ref){
        var group = ref.group;
        var groupBy = ref.groupBy;
        var items = ref.items;
        var remove = ref.remove;
        var toggle = ref.toggle;
        var isOpen = ref.isOpen;
return [_c('td',{staticClass:"text-center",attrs:{"colspan":_vm.headers.length}},[_c('v-container',{staticClass:"blue-grey lighten-5",attrs:{"fluid":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"align-self":"center"}},[_c('v-card',{staticClass:"blue-grey lighten-5",attrs:{"flat":""},on:{"click":function($event){return toggle()}}},[(isOpen)?_c('v-icon',{staticClass:"grey--text",attrs:{"x-small":""}},[_vm._v("mdi-format-list-group")]):_vm._e(),_c('span',{staticClass:"text-subtitle-2 pl-1"},[_vm._v(_vm._s(_vm.tableGroup(groupBy, items).title))]),(isOpen)?_c('v-icon',{staticClass:"primary--text",attrs:{"medium":""}},[_vm._v("mdi-chevron-up")]):_c('v-icon',{staticClass:"primary--text",attrs:{"medium":""}},[_vm._v("mdi-chevron-down")])],1)],1),_c('v-col',{attrs:{"align-self":"center"}},[_c('v-card',{staticClass:"blue-grey lighten-5",attrs:{"flat":""}},[_c('v-badge',{attrs:{"color":"grey lighten-2 black--text","content":_vm.tableGroup(groupBy, items).count}},[_c('v-chip',{staticClass:"text-subtitle-2 text-sm-h6 pa-4",class:_vm.scheduleHeaderStyle,attrs:{"color":"white dark"}},[_vm._v(" "+_vm._s(_vm.formattedGroupValue(group))+" ")])],1)],1)],1),_c('v-col',{staticClass:"text-center",attrs:{"align-self":"center"}},[_c('v-card',{staticClass:"blue-grey lighten-5",attrs:{"flat":""},on:{"click":function($event){return remove()}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":"primary","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-close-circle ")])]}}],null,true)},[_c('span',[_vm._v("Desagrupar por "+_vm._s(_vm.tableGroup(groupBy, items).title))])])],1)],1)],1)],1)],1)]}},{key:"item.created_at",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"mr-8"},[(_vm.isPhone)?_c('span',{staticClass:"text-subtitle-2"},[_vm._v(" Solicitado: ")]):_vm._e(),_c('span',{staticClass:"text-subtitle-2"},[_vm._v(_vm._s(_vm.justFormattedDate(item.created_at)))])])]}},{key:"item.partyName",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"mr-8"},[_c('span',{staticClass:"text-h4 text-sm-h6"},[_vm._v(_vm._s(item.partyName))])])]}},{key:"item.partyArea",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"mr-8"},[(_vm.isRequestRoleActivity(_vm.type))?_c('span',{staticClass:"text-h5 text-sm-overline font-weight-medium",class:((_vm.colorByRelationship(item)) + "--text")},[_vm._v(_vm._s(item.partyArea))]):_c('v-badge',{staticClass:"mr-3 font-weight-black",attrs:{"color":_vm.semaphoreColorByQty(_vm.areaQty(item)),"content":_vm.areaQty(item)}},[_c('div',{staticClass:"text-h5 text-sm-overline font-weight-medium",class:((_vm.colorByRelationship(item)) + "--text"),attrs:{"width":"140px"}},[_vm._v(" "+_vm._s(item.partyArea)+" ")])])],1)]}},(_vm.isAccessRequest)?{key:"item.siteName",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"mr-8"},[_c('span',{staticClass:"text-h4 av-text-h4 text-sm-subtitle-2 font-weight-medium",class:_vm.siteStyle},[_vm._v(_vm._s(item.siteName))])])]}}:null,(_vm.isAccessRequest)?{key:"item.resource.schedule_start",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"mr-8"},[_c('span',{staticClass:"text-h5 text-sm-h6"},[_vm._v(_vm._s(_vm.groupHeaderFormattedDate(item.resource.schedule_start)))])])]}}:null,(_vm.isAccessRequest)?{key:"item.resource.time",fn:function(ref){
        var item = ref.item;
return [_c('v-chip',{staticClass:"text-h5 text-sm-h6 pa-4 mr-8",attrs:{"color":"brown lighten-5"}},[_vm._v(" "+_vm._s(item.resource.time)+" ")])]}}:null,(_vm.isDocRequirement)?{key:"item.resource.name",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"mr-8"},[_c('span',{staticClass:"text-h5 text-sm-subtitle-2 font-weight-dark"},[_vm._v(_vm._s(item.resource.name))])])]}}:null,(_vm.isDocRequirement)?{key:"item.resource.size",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"mr-8"},[_c('span',{staticClass:"text-h5 text-sm-subtitle-2 font-weight-dark"},[_vm._v(_vm._s((item.resource.size / (1024 * 1024)).toFixed(2))+" Mb")])])]}}:null,{key:"item.workflow.stages",fn:function(ref){
        var item = ref.item;
return [(item.workflow.stages.length > 0)?_c('div',{staticClass:"mr-8 mx-sm-auto"},[_c('div',[_vm._l((_vm.lastNFilter(item, 2)),function(stage){return _c('status-icon',{key:stage.position,attrs:{"stage":stage,"item":item,"isCurrentStatusRejected":_vm.isCurrentStatusRejectedOnWorkflowFor(item),"statusOf":_vm.statusOnWorkflowFor(item)}})}),(!_vm.isCurrentEndStage(item))?_c('next-status-icon',{attrs:{"stage":_vm.currentStageOf(item.workflow.stages),"item":item,"isCurrentStatusRejected":_vm.isCurrentStatusRejectedOnWorkflowFor(item),"currentStatus":_vm.currentStatusOnWorkflowFor(item)}}):_vm._e()],2)]):_vm._e()]}},{key:"item.activity",fn:function(ref){
        var item = ref.item;
return [(item.workflow.stages.length > 0)?_c('div',{staticClass:"my-auto"},[_c('next-stage-btns',{attrs:{"type":_vm.type,"item":item,"dialog":_vm.$refs.dialog,"currentStatus":_vm.currentStatusOnWorkflowFor(item)}})],1):_vm._e()]}}],null,true)})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }