export function headersBy(type) {
  return type === 'request'
    ? requestsHeaders()
    : type === 'auth'
    ? authsHeaders()
    : docsHeaders()
}

function requestsHeaders() {
  return [
    { text: 'Solicitado', value: 'created_at', align: 'start' },
    { text: 'Sitio', value: 'siteName', align: 'center' },
    { text: 'Ingreso', value: 'resource.schedule_start', align: 'center' },
    { text: 'Tiempo', value: 'resource.time', align: 'center' },
    { text: 'Estado', value: 'workflow.stages', align: 'center' },
    {
      text: 'Actividad',
      value: 'activity',
      align: 'center',
      filterable: false,
      sortable: false,
      groupable: false
    }
  ]
}

function authsHeaders() {
  return [
    { text: 'Solicitado', value: 'created_at', align: 'start' },
    { text: 'Área', value: 'partyArea', align: 'center' },
    { text: 'Para', value: 'partyName', align: 'center' },
    { text: 'Sitio', value: 'siteName', align: 'center' },
    { text: 'Ingreso', value: 'resource.schedule_start', align: 'center' },
    { text: 'Tiempo', value: 'resource.time', align: 'center' },
    { text: 'Estado', value: 'workflow.stages', align: 'center' },
    {
      text: 'Actividad',
      value: 'activity',
      align: 'center',
      filterable: false,
      sortable: false,
      groupable: false
    }
  ]
}

function docsHeaders() {
  return [
    { text: 'Solicitado', value: 'created_at', align: 'start' },
    { text: 'Área', value: 'partyArea', align: 'center' },
    { text: 'Para', value: 'partyName', align: 'center' },
    { text: 'Documento', value: 'resource.name', align: 'center' },
    { text: 'Tamaño', value: 'resource.size', align: 'center' },
    { text: 'Estado', value: 'workflow.stages', align: 'center' },
    {
      text: 'Actividad',
      value: 'activity',
      align: 'center',
      filterable: false,
      sortable: false,
      groupable: false
    }
  ]
}
