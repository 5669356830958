export function nextActionTexts(type, currentStatus) {
  return nextActionsContents.find(
    contents => contents.type === type && contents.status === currentStatus
  )
}
const nextActionsContents = [
  // Access Requests
  {
    type: 'request',
    status: 'expired',
    label: 'No atendió',
    tootltip: 'No se atendió oportunamente',
    icon: 'mdi-timer-sand-empty'
  },
  {
    type: 'request',
    status: 'to_auth1',
    label: 'Por aceptar',
    tootltip: 'Por aceptar',
    icon: 'mdi-timer-sand'
  },
  {
    type: 'request',
    status: 'to_auth1_due',
    label: 'Tiempo para aceptar vencido',
    tootltip: 'Tiempo para aceptar vencido',
    icon: 'mdi-timer-sand-empty'
  },
  {
    type: 'request',
    status: 'to_auth2',
    label: 'Por autorizar',
    tootltip: 'Por autorizar',
    icon: 'mdi-timer-sand'
  },
  {
    type: 'request',
    status: 'to_auth2_due',
    label: 'Tiempo para autorizar vencido',
    tootltip: 'Tiempo para autorizar vencido',
    icon: 'mdi-timer-sand-empty'
  },
  {
    type: 'request',
    status: 'to_auth3',
    label: 'Por valorar',
    tootltip: 'Por valorar',
    icon: 'mdi-timer-sand'
  },
  {
    type: 'request',
    status: 'to_auth3_due',
    label: 'Sin chequeo médico',
    tootltip: 'Pasó el tiempo para recibir pase médico',
    icon: 'mdi-timer-sand-empty'
  },
  {
    type: 'request',
    status: 'rejected',
    label: 'Archivado',
    tootltip: 'Vencido/Declinado',
    icon: 'mdi-archive'
  },
  {
    type: 'request',
    status: 'to_checkin',
    label: 'Ver mi pase médico',
    tootltip: 'Ver mi pase médico',
    icon: 'mdi-qrcode-scan'
  },
  {
    type: 'request',
    status: 'to_checkin_due',
    label: 'Pasó el tiempo para ingresar',
    tootltip: 'Pasó el tiempo para ingresar',
    icon: 'mdi-door-closed-lock'
  },
  {
    type: 'request',
    status: 'canceled',
    label: 'Lo cancelé',
    tootltip: 'Cancelado',
    icon: 'mdi-calendar-remove'
  },
  {
    type: 'request',
    status: 'no_show',
    label: 'No asistí',
    tootltip: 'Ausencia',
    icon: 'mdi-bed'
  },
  {
    type: 'request',
    status: 'checkin',
    label: 'En sitio',
    tootltip: 'En sitio',
    icon: 'mdi-door'
  },
  {
    type: 'request',
    status: 'pass1',
    label: 'Consulta agendada',
    tootltip: 'Consulta agendada',
    icon: 'mdi-medical-bag'
  },
  {
    type: 'request',
    status: 'pass2',
    label: 'Con pase médico',
    tootltip: 'En sitio con pase médico',
    icon: 'mdi-qrcode-scan'
  },
  {
    type: 'request',
    status: 'checkout',
    label: 'Ya salí',
    tootltip: 'Ya salí',
    icon: 'mdi-archive'
  },
  {
    type: 'request',
    status: 'error',
    label: 'Fuera de tiempo',
    tootltip: 'Algo salío mal',
    icon: 'mdi-clock-remove'
  },
  {
    type: 'auth',
    status: 'expired',
    label: 'No atendió',
    tootltip: 'No se atendió oportunamente',
    icon: 'mdi-timer-sand-empty'
  },
  {
    type: 'auth',
    status: 'to_auth1',
    label: 'Por aceptar',
    tootltip: 'Por aceptar',
    icon: 'mdi-timer-sand'
  },
  {
    type: 'auth',
    status: 'to_auth1_due',
    label: 'Tiempo para aceptar vencido',
    tootltip: 'Tiempo para aceptar vencido',
    icon: 'mdi-timer-sand-empty'
  },
  {
    type: 'auth',
    status: 'to_auth2',
    label: 'Por autorizar',
    tootltip: 'Por autorizar',
    icon: 'mdi-timer-sand'
  },
  {
    type: 'auth',
    status: 'to_auth2_due',
    label: 'Tiempo para autorizar vencido',
    tootltip: 'Tiempo para autorizar vencido',
    icon: 'mdi-timer-sand-empty'
  },
  {
    type: 'auth',
    status: 'to_auth3',
    label: 'Por valorar',
    tootltip: 'Por valorar',
    icon: 'mdi-timer-sand'
  },
  {
    type: 'auth',
    status: 'to_auth3_due',
    label: 'Sin chequeo médico',
    tootltip: 'Pasó el tiempo para recibir pase médico',
    icon: 'mdi-timer-sand-empty'
  },
  {
    type: 'auth',
    status: 'rejected',
    label: 'Archivado',
    tootltip: 'Vencido/Declinado',
    icon: 'mdi-archive'
  },
  {
    type: 'auth',
    status: 'to_checkin',
    label: 'A consulta',
    tootltip: 'A consulta',
    icon: 'mdi-door-closed-lock'
  },
  {
    type: 'auth',
    status: 'to_checkin_due',
    label: 'Pasó el tiempo para ingresar',
    tootltip: 'Pasó el tiempo para ingresar',
    icon: 'mdi-door-closed-lock'
  },
  {
    type: 'auth',
    status: 'canceled',
    label: 'Lo canceló',
    tootltip: 'Canceló asistencia',
    icon: 'mdi-calendar-remove'
  },
  {
    type: 'auth',
    status: 'no_show',
    label: 'No asistió',
    tootltip: 'Ausencia',
    icon: 'mdi-bed'
  },
  {
    type: 'auth',
    status: 'checkin',
    label: 'En sitio',
    tootltip: 'En sitio',
    icon: 'mdi-door'
  },
  {
    type: 'auth',
    status: 'pass1',
    label: 'Consulta agendada',
    tootltip: 'Consulta agendada',
    icon: 'mdi-medical-bag'
  },
  {
    type: 'auth',
    status: 'pass2',
    label: 'Con pase médico',
    tootltip: 'En sitio con pase médico',
    icon: 'mdi-qrcode-scan'
  },
  {
    type: 'auth',
    status: 'checkout',
    label: 'Ya salió',
    tootltip: 'Ya salió',
    icon: 'mdi-archive'
  },
  {
    type: 'auth',
    status: 'error',
    label: 'Fuera de tiempo',
    tootltip: 'Algo salío mal',
    icon: 'mdi-clock-remove'
  },
  // Docs Requirements
  {
    type: 'auth_doc',
    status: 'expired',
    label: 'No fue atendido',
    tootltip: 'No se atendió oportunamente',
    icon: 'mdi-timer-sand-empty'
  },
  {
    type: 'auth_doc',
    status: 'to_auth',
    label: 'Por autorizar',
    tootltip: 'Por autorizar',
    icon: 'mdi-timer-sand'
  },
  {
    type: 'auth_doc',
    status: 'rejected',
    label: 'Archivado',
    tootltip: 'Vencido/Declinado',
    icon: 'mdi-archive'
  },
  {
    type: 'auth_doc',
    status: 'end',
    label: 'Aceptado',
    tootltip: 'Documento aceptado',
    icon: 'mdi-archive'
  },
  {
    type: 'auth_doc',
    status: 'error',
    label: 'Fuera de tiempo',
    tootltip: 'Algo salío mal',
    icon: 'mdi-clock-remove'
  }
]
