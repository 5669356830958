import { hasRequestPendingAuthsOf } from '@/components/requests/auths/authsControl'
import { isAuthRoleActivity, isRequestRoleActivity } from '../activityControl'
import {
  currentStageOf,
  isCheckoutStage
} from '@/components/requests/workflow/stagesControl'
// import { isStageApproved } from '../status/statusControl'
import { isBetweenAnyAndCheckin } from '../pass/checkinTimeControl'

export function getStagesFilter(headers, table) {
  const filter = {
    filter: stages => {
      if (!table.onlyPendingSelection) return true
      if (stages && stages.length) {
        const currentStage = currentStageOf(stages)
        if (isAuthRoleActivity(table.type)) {
          const hasPendingAuths = hasRequestPendingAuthsOf(
            stages,
            table.$store,
            table.type
          )
          // const isLastAuthApproved = isStageApproved(currentStage)
          // return hasPendingAuths && isLastAuthApproved
          return hasPendingAuths
        } else if (isRequestRoleActivity(table.type)) {
          return !isCheckoutStage(currentStage)
        }
      } else {
        return true
      }
    }
  }
  return getFilterObj(headers, filter, 'workflow.stages')
}

export function getScheduleFilter(headers, table) {
  const filter = {
    filter: scheduleStart => {
      if (!table.onlyPendingSelection) return true
      return isBetweenAnyAndCheckin(scheduleStart)
    }
  }
  return getFilterObj(headers, filter, 'resource.schedule_start')
}

export function getFilterObj(headers, filter, fieldName) {
  const obj = headers.find(header => header.value === fieldName)
  const index = headers.findIndex(header => header.value === fieldName)
  const objWithFilter = {
    index,
    obj: { ...obj, ...filter }
  }
  return objWithFilter
}
