<template>
  <v-card>
    <v-toolbar rounded outlined>
      <v-spacer></v-spacer>
      <v-toolbar-title
        ><v-btn rounded class="pa-4 grey darken-4" dark to="Request"
          ><v-icon class="pr-1" color="primary">mdi-plus-circle</v-icon> Nueva
          solicitud
        </v-btn></v-toolbar-title
      >
      <v-spacer></v-spacer>

      <template v-if="isSomeReviewer" v-slot:extension>
        <v-tabs icons-and-text show-arrows v-model="tab" grow>
          <v-tabs-slider class="slider-border" color="identity"></v-tabs-slider>
          <v-tab v-for="(item, i) in items" :key="i">
            {{ item.title }}
            <v-icon color="identity">{{ item.icon }}</v-icon>
          </v-tab>
        </v-tabs>
      </template>
    </v-toolbar>

    <v-tabs-items v-model="tab" class="mt-1">
      <v-tab-item key="1"><requests-table :type="'request'"/></v-tab-item>
      <v-tab-item key="2"><requests-table :type="'auth'"/></v-tab-item>
      <!-- <v-tab-item key="3"><requests-table :type="'auth_doc'"/></v-tab-item> -->
    </v-tabs-items>
  </v-card>
</template>

<script>
import RequestsTable from '@/components/requests/table/RequestsTable'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    RequestsTable
  },
  data: () => ({
    tab: null,
    items: [
      { title: 'Mis Accesos', icon: 'mdi-calendar-multiple' },
      { title: 'Autorizaciones', icon: 'mdi-shield-check' }
      // { title: 'Documentos', icon: 'mdi-file-document-multiple' }
    ]
  }),
  computed: {
    ...mapGetters('user', ['isSomeReviewer']),
    ...mapGetters('requests', ['isNewRequestSent'])
  },
  mounted() {
    if (this.isNewRequestSent) {
      this.showRequests()
      this.setOffNewRequestSent()
    } else {
      this.tab = this.isSomeReviewer ? 1 : 0
    }
  },
  methods: {
    ...mapActions('requests', ['setOffNewRequestSent']),
    showRequests() {
      this.tab = 0
    }
  }
}
</script>

<style lang="scss">
.v-row-group__header .mdi-close,
.v-row-group__header .mdi-minus {
  font-size: 12px !important;
}
.v-tab {
  padding-bottom: 8px;
  height: 45px;
  font-weight: 700;
  font-size: 15px;
  letter-spacing: 0.5px;
  // width: 145px !important;
}
.av-btn {
  height: 45px;
  font-weight: 700;
  font-size: 15px;
  letter-spacing: 0.5px;
}
.v-tab:before {
  background-color: transparent;
}
.v-tabs-slider-wrapper {
  height: 3px !important;
}
</style>
