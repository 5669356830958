<template>
  <v-tooltip top v-if="icon.defined">
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        v-bind="attrs"
        v-on="on"
        :size="icon.size"
        class="mr-1"
        :color="iconColor"
      >
        {{ icon.symbol }}
      </v-icon>
    </template>
    <span>{{ tooltip }}</span>
  </v-tooltip>
</template>

<script>
import { mapGetters } from 'vuex'
import { statusIcon } from '@/components/requests/status/statusIconModel'
import {
  APPROVED_STAGE,
  ON_CHECKIN_NO_SHOW,
  ON_CHECKIN_STAGE,
  ON_CHECKOUT_STAGE,
  ON_EXPIRED_REQUEST,
  ON_LOADING_NEXT_STATUS_STAGE,
  ON_PASS1_STAGE,
  ON_PASS2_STAGE,
  ON_REQUEST_CANCELED,
  ON_UNMATCH_STATUS_ERROR,
  REJECTED_REQUEST,
  TO_AUTH1_DUE_STAGE,
  TO_AUTH1_STAGE,
  TO_AUTH2_DUE_STAGE,
  TO_AUTH2_STAGE,
  TO_AUTH3_DUE_STAGE,
  TO_AUTH3_STAGE,
  TO_CHECKIN_DUE_STAGE,
  TO_CHECKIN_STAGE
} from '@/components/requests/status/statusControl'
import { periodIn, resourceInRequest } from '../reqsControl'
import { isWhithinTimeToAuth } from '../auths/authsTimeControl'
import {
  currentStageOf,
  isNextStage
} from '@/components/requests/workflow/stagesControl'
import {
  STAGE_NOT_ON_ALERT,
  STAGE_ON_DUE,
  STAGE_ON_EARLIER,
  STAGE_ON_LATE
} from '@/components/requests/status/stageOnAlert'

export default {
  props: {
    stage: {
      type: Object,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    isCurrentStatusRejected: {
      type: Function,
      required: true
    },
    currentStatus: {
      type: Function,
      required: true
    }
  },

  computed: {
    ...mapGetters('assignments', ['areaByRoleId']),
    ...mapGetters('workflows', [
      'currentNextStageId',
      'currentNextStageDescription',
      'willNextStageAuth',
      'willNextStageAuth3',
      'statusColorByStage'
    ]),
    ...mapGetters('assignments', ['areaInStage']),

    current() {
      return currentStageOf(this.item.workflow.stages)
    },
    status() {
      return this.currentStatus(
        this.current,
        this.item,
        isNextStage(this.$store)
      )
    },

    statusColor() {
      return this.statusColorByStage(this.stage, this.status, this.item)
    },

    icon() {
      return statusIcon(this.currentNextStageId(this.item), 'approved')
    },

    iconColor() {
      const val =
        this.isCurrentStatusRejected(this.current, this.item, this.$store) ||
        !isWhithinTimeToAuth(periodIn(resourceInRequest(this.item)))
          ? 'grey lighten-4'
          : this.statusColor.icon
      return val
    },

    tooltip() {
      return this.nextStatusContents() ? this.nextStatusContents().message : ''
    }
  },

  methods: {
    nextStatusContents() {
      const alert = this.item.alert || STAGE_NOT_ON_ALERT
      return this.nextStatusContentsMap.find(
        msg =>
          (msg.last_stage_alert === alert || msg.last_stage_alert === 'any') &&
          msg.request_status === this.status
      )
    }
  },

  created() {
    this.nextStatusContentsMap = [
      // REJECTED
      {
        last_stage_alert: STAGE_NOT_ON_ALERT,
        request_status: REJECTED_REQUEST,
        message: 'Solicitud rechazada a tiempo'
      },
      {
        last_stage_alert: STAGE_ON_EARLIER,
        request_status: REJECTED_REQUEST,
        message: 'Solicitud rechazada a tiempo'
      },
      {
        last_stage_alert: STAGE_ON_LATE,
        request_status: REJECTED_REQUEST,
        message: 'Solicitud rechazada'
      },
      {
        last_stage_alert: STAGE_ON_DUE,
        request_status: REJECTED_REQUEST,
        message: 'Rechazada y caducada'
      },
      // APPROVED
      {
        last_stage_alert: STAGE_NOT_ON_ALERT,
        request_status: APPROVED_STAGE,
        message: 'Solicitud aprobada a tiempo'
      },
      {
        last_stage_alert: STAGE_ON_EARLIER,
        request_status: APPROVED_STAGE,
        message: 'Solicitud aprobada a tiempo'
      },
      {
        last_stage_alert: STAGE_ON_LATE,
        request_status: APPROVED_STAGE,
        message: 'Solicitud aprobada'
      },
      {
        last_stage_alert: STAGE_ON_DUE,
        request_status: APPROVED_STAGE,
        message: 'Aprobada pero caducada'
      },
      // TO AUTH 1
      {
        last_stage_alert: STAGE_NOT_ON_ALERT,
        request_status: TO_AUTH1_STAGE,
        message: 'En aprobación por el Jefe Inmediato'
      },
      {
        last_stage_alert: STAGE_ON_EARLIER,
        request_status: TO_AUTH1_STAGE,
        message: 'Por aprobar a tiempo'
      },
      {
        last_stage_alert: STAGE_ON_LATE,
        request_status: TO_AUTH1_STAGE,
        message: 'Por aprobar'
      },
      {
        last_stage_alert: STAGE_ON_DUE,
        request_status: TO_AUTH1_STAGE,
        message: 'Caducó antes de aprobar'
      },
      {
        last_stage_alert: 'any',
        request_status: TO_AUTH1_DUE_STAGE,
        message: 'Caducó antes de aprobar'
      },
      // TO AUTH 2
      {
        last_stage_alert: STAGE_NOT_ON_ALERT,
        request_status: TO_AUTH2_STAGE,
        message: 'En aprobación por Planta'
      },
      {
        last_stage_alert: STAGE_ON_EARLIER,
        request_status: TO_AUTH2_STAGE,
        message: 'Por autorizar a tiempo'
      },
      {
        last_stage_alert: STAGE_ON_LATE,
        request_status: TO_AUTH2_STAGE,
        message: 'Por autorizar'
      },
      {
        last_stage_alert: STAGE_ON_DUE,
        request_status: TO_AUTH2_STAGE,
        message: 'Caducó anter de autorizar'
      },
      {
        last_stage_alert: 'any',
        request_status: TO_AUTH2_DUE_STAGE,
        message: 'Caducó antes de aprobar'
      },
      // PASS 1
      {
        last_stage_alert: STAGE_NOT_ON_ALERT,
        request_status: ON_PASS1_STAGE,
        message: 'A valoración con el médico'
      },
      {
        last_stage_alert: STAGE_ON_EARLIER,
        request_status: ON_PASS1_STAGE,
        message: 'Esperando revisión médica'
      },
      {
        last_stage_alert: STAGE_ON_LATE,
        request_status: ON_PASS1_STAGE,
        message: 'Esperando revisión médica'
      },
      {
        last_stage_alert: STAGE_ON_DUE,
        request_status: ON_PASS1_STAGE,
        message: 'Pase médico caducado'
      },
      // TO AUTH 3
      {
        last_stage_alert: STAGE_NOT_ON_ALERT,
        request_status: TO_AUTH3_STAGE,
        message: 'Esperando valoración'
      },
      {
        last_stage_alert: STAGE_ON_EARLIER,
        request_status: TO_AUTH3_STAGE,
        message: 'Por revisar a tiempo'
      },
      {
        last_stage_alert: STAGE_ON_LATE,
        request_status: TO_AUTH3_STAGE,
        message: 'Por revisar'
      },
      {
        last_stage_alert: STAGE_ON_DUE,
        request_status: TO_AUTH3_STAGE,
        message: 'Caducó antes de la revisión'
      },
      {
        last_stage_alert: 'any',
        request_status: TO_AUTH3_DUE_STAGE,
        message: 'Caducó antes de aprobar'
      },
      // CHECKIN
      {
        last_stage_alert: STAGE_NOT_ON_ALERT,
        request_status: TO_CHECKIN_STAGE,
        message: 'Listo para ingresar'
      },
      {
        last_stage_alert: STAGE_ON_EARLIER,
        request_status: TO_CHECKIN_STAGE,
        message: 'Listo para ingresar'
      },
      {
        last_stage_alert: STAGE_ON_LATE,
        request_status: TO_CHECKIN_STAGE,
        message: 'Ya va tarde para ingresar'
      },
      {
        last_stage_alert: STAGE_ON_DUE,
        request_status: TO_CHECKIN_STAGE,
        message: 'El acceso ha caducado'
      },
      {
        last_stage_alert: 'any',
        request_status: ON_CHECKIN_STAGE,
        message: 'Asistiendo a valoración'
      },
      {
        last_stage_alert: 'any',
        request_status: TO_CHECKIN_DUE_STAGE,
        message: 'El acceso ha caducado'
      },
      {
        last_stage_alert: 'any',
        request_status: ON_CHECKIN_NO_SHOW,
        message: 'No se presentó'
      },
      {
        last_stage_alert: 'any',
        request_status: ON_REQUEST_CANCELED,
        message: 'No se presentó'
      },
      // PASS2
      {
        last_stage_alert: 'any',
        request_status: ON_PASS2_STAGE,
        message: 'Con multipase médico'
      },
      // CHECKOUT
      {
        last_stage_alert: STAGE_NOT_ON_ALERT,
        request_status: ON_CHECKOUT_STAGE,
        message: 'Salió'
      },
      {
        last_stage_alert: STAGE_ON_EARLIER,
        request_status: ON_CHECKOUT_STAGE,
        message: 'Salió anticipadamente'
      },
      {
        last_stage_alert: STAGE_ON_LATE,
        request_status: ON_CHECKOUT_STAGE,
        message: 'Salió después de lo esperado'
      },
      {
        last_stage_alert: STAGE_ON_DUE,
        request_status: ON_CHECKOUT_STAGE,
        message: 'Caducó'
      },
      // Expired
      {
        last_stage_alert: 'any',
        request_status: ON_EXPIRED_REQUEST,
        message: 'Caducó'
      },
      // Loading
      {
        last_stage_alert: 'any',
        request_status: ON_LOADING_NEXT_STATUS_STAGE,
        message: 'Caducó'
      },
      // Error
      {
        last_stage_alert: 'any',
        request_status: ON_UNMATCH_STATUS_ERROR,
        message: 'Fuera de tiempo'
      }
    ]
  }
}
</script>
