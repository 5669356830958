<template>
  <v-card class="mx-auto" shaped>
    <v-list-item three-line>
      <v-list-item-content>
        <div class="overline mb-4 headline identity--text">
          {{ passiveSiteNameById(editedItem.site) }}
        </div>
        <v-list-item-title class="headline mb-1">
          {{ editedItem.party.name }}
        </v-list-item-title>
        <v-list-item-subtitle>{{
          editedItem.resource.motivation
        }}</v-list-item-subtitle>
        <v-chip-group column>
          <v-chip pill class="text-subtitle-1 font-weight-bold mt-2 pr-4"
            ><v-icon color="identity accent-4" class="mr-1">
              mdi-calendar </v-icon
            >{{ passiveScheduleStart(editedItem.resource.schedule_start) }}
          </v-chip>
          <v-chip pill class="text-subtitle-1 font-weight-bold mt-2 pl-3 pr-5">
            <v-icon color="identity accent-4" class="mr-1"> mdi-clock </v-icon
            >{{ passiveTime(editedItem.resource.time) }}</v-chip
          >
        </v-chip-group>
      </v-list-item-content>

      <v-list-item-avatar
        outlined
        width="50%"
        height="50%"
        max-width="120"
        max-height="120"
        color="grey"
      >
        <v-img :src="passiveSiteImgById(editedItem.resource.site)"></v-img>
      </v-list-item-avatar>
    </v-list-item>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { managedImg } from '@/micro/img'
import { longFormattedDate } from '@/micro/time/dateFormat'

export default {
  props: {
    editedItem: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapGetters('sites', ['siteById']),

    passiveSiteNameById() {
      return siteId => {
        const site = this.siteById(siteId)
        return site ? site.name : ''
      }
    },

    passiveSiteImgById() {
      return siteId => {
        const site = this.siteById(siteId)
        return site ? this.managedImg(site.avatar) : ''
      }
    },

    passiveScheduleStart() {
      return schedule => (schedule ? this.longFormattedDate(schedule) : '')
    },

    passiveTime() {
      return time => time || ''
    }
  },

  methods: {
    longFormattedDate,
    managedImg
  }
}
</script>

<style lang="scss" scoped>
.theme--light.v-card {
  background-color: #fefefe !important;
  border-left: 15px solid $identity !important;
}
.outlined {
  border: 3px solid $identity;
  border-radius: 50%;
  height: 56px;
  width: 56px;
}
span.v-chip__content {
  font-size: 18px;
}
</style>
