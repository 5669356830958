<template>
  <v-card class="rounded-lg" :color="statusColor.card">
    <v-container>
      <v-row dense>
        <v-col cols="4" align-center class="text-center pa-6"
          ><v-icon :size="icon.size + 16" :color="statusColor.icon">
            {{ icon.symbol }}
          </v-icon>
          <v-card-text
            class="text-body-2"
            :class="commentTextColor"
            v-if="!$vuetify.breakpoint.mobile"
            >{{ stage.comment }}
          </v-card-text>
        </v-col>
        <v-col cols="8" class="pa-3">
          <v-card-title class="headline" :class="statusTextColor">
            {{ statusText.title }}
            <div class="text-overline ml-2">{{ statusText.subtitle }}</div>
          </v-card-title>
          <v-card-subtitle
            class="text-subtitle-2 mt-2"
            :class="positionTextColor"
          >
            <p class="text-h5 text-uppercase">{{ areaInStage(stage) }}</p>
            <span class="text-title" :class="effectiveDateTextColor">{{
              effective
            }}</span>
          </v-card-subtitle>
          <v-card-text
            :class="commentTextColor"
            v-if="$vuetify.breakpoint.mobile"
            >{{ stage.comment }}
          </v-card-text>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { statusIcon } from '@/components/requests/status/statusIconModel'
import { formattedDateTime } from '@/micro/time/dateFormat'

export default {
  props: {
    editedItem: {
      type: Object,
      required: true
    },
    stage: {
      type: Object,
      required: true
    },
    statusOf: {
      type: Function,
      required: true
    }
  },
  computed: {
    ...mapGetters('assignments', ['areaInStage']),
    ...mapGetters('workflows', ['statusColorByStage']),
    icon() {
      return this.statusIcon(this.stage.id, this.stage.status)
    },
    statusColor() {
      return this.statusColorByStage(this.stage, this.status, this.editedItem)
    },
    statusTextColor() {
      if (this.status === 'approved_alert') {
        return `grey--text text--darken-2 font-weight-medium`
      }
      return this.stageTextColor
    },
    positionTextColor() {
      if (this.status === 'approved_alert') {
        return 'grey--text text--darken-2'
      }
      return this.stageTextColor
    },
    effectiveDateTextColor() {
      if (this.status === 'approved_alert') {
        return 'black--text'
      }
      return this.stageTextColor
    },
    commentTextColor() {
      if (this.status === 'approved_alert') {
        return 'grey--text text--darken-4'
      }
      return this.stageTextColor
    },
    stageTextColor() {
      const parts = this.statusColor.text.split(' ')
      return `${parts[0]}--text text--${parts[1]}`
    },
    statusText() {
      const actionTxt =
        this.stage.id === 'auth1'
          ? 'Aprobó'
          : this.stage.id === 'auth2'
          ? 'Autorizó'
          : 'Validó'
      const title =
        this.status === 'approved' || this.status === 'approved_alert'
          ? actionTxt
          : 'Rechazó'
      const subtitle = this.status === 'approved_alert' ? '' : ''
      return { title, subtitle }
    },
    effective() {
      return formattedDateTime(this.stage.effective)
    },
    status() {
      return this.statusOf(this.stage, this.editedItem)
    }
  },
  methods: {
    statusIcon
  }
}
</script>
